import { Tabs } from "antd"
import * as React from "react"
import { UtmV2BonusesList } from "../BonusesList"

export const UtmSettingsV2: React.FunctionComponent = () => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Utm bonuses" key="1">
        <UtmV2BonusesList />
      </Tabs.TabPane>
    </Tabs>
  )
}
