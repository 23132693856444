import { PaginationStore } from "stores/data/pagination.store"
import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { EntityToUpdate } from "abstract/entity-to-update"
import { createUtmBonus, deleteUtmBonus, getUtmV2Bonuses, updateUtmBonus } from "../api"

export class UtmBonus {
  constructor(
    public id: string,
    public source: string,
    public medium: string,
    public campaign: string,
    public content: string,
    public extra: string,
    public term: string,
    public reward: number,
    public active: boolean,
  ) {}
}

export class UtmV2BonusesStore extends PaginationStore<UtmBonus> {
  @observable
  private _utmBonuses: UtmBonus[]
  @observable
  private _selectedElement: UtmBonus = {} as UtmBonus

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<UtmBonus[]> {
    const { page, limit } = this.pagination

    this.resetFuncAfterSuccess()

    const res = await this.defaultFunction(getUtmV2Bonuses, this.filter, page, limit)
    console.log("res", res)
    transaction(() => {
      this._utmBonuses = res.data
      this.total = res.total
    })

    return res.data
  }

  async update(data: EntityToUpdate<UtmBonus>) {
    await this.defaultFunction(updateUtmBonus, this._selectedElement.id, data)
  }

  async create(data: EntityToUpdate<UtmBonus>) {
    await this.defaultFunction(createUtmBonus, data)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteUtmBonus, id)
  }

  @computed
  get utmBonuses(): UtmBonus[] {
    return this._utmBonuses
  }

  @computed
  get selectedElement(): UtmBonus {
    return this._selectedElement
  }

  set selectedElement(value: UtmBonus) {
    this._selectedElement = value
  }
  clearSelectedElement() {
    this._selectedElement = {} as UtmBonus
  }
}
