import {
  MailOutlined,
  SettingOutlined,
  FileImageOutlined,
  TeamOutlined,
  NotificationOutlined,
  RedditOutlined,
  TagsOutlined,
  CalendarOutlined,
  GiftOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  BoldOutlined,
} from "@ant-design/icons"

import { GameDetails } from "domains/games/components/GameDetails"
import { GamesList } from "domains/games/components/GamesList"
import { ManagingJsonList } from "domains/managingJson/components/List"
import { ManagingSpinCollectList } from "domains/managingSpinCollect/components/ManagingSpinCollectList"
import { MarketplaceSettings } from "domains/marketplace/components"
import { PromoPopUpsLists } from "domains/promo-pop-ups/components/promo-popups-list"
import { SubscriptionList } from "domains/subscription/components/SubscriptionList"
import { UtmSettings } from "domains/utm/components"
import { AccountDetails } from "domains/accounts/components/account-details"
import { AccountsList } from "domains/accounts/components/accounts-list"
import { BoostersList } from "domains/boosters/components/boosters.list"
import { ChallengesList } from "domains/challenges/components/List/challenges.list"
import { EmailNotificationsList } from "domains/email-notifications/components/email-notifications-list"
import { GameAccountDetails } from "domains/game-accounts/components/game-account-details"
import { GameAccountsList } from "domains/game-accounts/components/game-accounts-list"
import { RafflesList } from "domains/raffles/components/RafflesList"
import { PopUpsList } from "domains/pop-ups/components/pop-ups-list"
import { PurchasedGiftCardsList } from "domains/purchased-gift-cards/components/purchased-gift-cards.list"
import { BannersSettings } from "domains/banners/components/banners-settings"
import { PromocodesSettings } from "domains/promocodes/components"
import { RewardsList } from "domains/promocodes/components/Rewards"
import { NotificationsList } from "domains/notifications/components/notifications.list"
import { EditNavbarAvatarTabs } from "domains/managingJson/components/EditNavbarAvatarTabs"
import { EditPostionOfScreens } from "domains/managingJson/components/EditPostionOfScreens"

import { EditDynamicUI } from "domains/managingJson/components/EditDynamicUI"
import { InGameBannersList } from "domains/in-game-banners/components/in-game-banners-list"
import { CodesList } from "domains/codesInventory/components"
import { AdsBannersList } from "domains/ads-banners/components/ads-banners-list"
import { BlacklistList } from "domains/blacklist/components/blacklist"
import { EditMobileConfig } from "domains/managingJson/components/EditMobileConfig"
import { EmailCtaList } from "domains/emailsCTA/components/email-cta-list"
import { EditBlockedGameModes } from "domains/managingJson/components/EditBlockedGameModes"
import { EditBlockedGameModesV2 } from "domains/managingJson/components/EditBlockedGameModesV2"
import { OptimovePopups } from "domains/popup-templates/components/OptimovePopups"
import { SpecialEventsPage } from "domains/specialEvents/pages/main"
import { ProductsList } from "domains/products/components/list/products.list"
import { VariousConfig } from "domains/managingJson/components/Various"
import { BulkRewards } from "domains/bulk-rewards/pages/bulkRewards"
import { EditSpecialEventPage } from "domains/specialEvents/pages/edit"
import InternalOWAdvertisersPage from "domains/internal-offerwall/components/advertisersPage"
import InternalOWAdvertiserPage from "domains/internal-offerwall/components/advertiserPage"
import { SettingsPage } from "domains/internal-offerwall/components/SettingsPage"
import { ContestsList } from "domains/contests/components/contests-list"
import { GiftoinSettings } from "domains/giftoin/components/settings"
import InternalOWExternalProvidersPage from "domains/internal-offerwall/components/externalProvidersPage"
import { UtmSettingsV2 } from "domains/utm-v2/components"

type RouteType = {
  link: string;
  component?: any;
  name?: string;
  icon?: JSX.Element;
};
type RoutesType = RouteType & { children?: RouteType[] };

export const routes: RoutesType[] = [
  {
    link: "accounts/details/:id",
    component: AccountDetails,
  },
  {
    link: "game-accounts/details/:id",
    component: GameAccountDetails,
  },
  {
    link: "accounts/list",
    component: AccountsList,
    name: "Accounts",
    icon: <TeamOutlined />,
  },
  {
    link: "communications",
    name: "Communications",
    icon: <MailOutlined />,
    children: [
      {
        link: "communications/banners",
        component: BannersSettings,
        name: "Lounge Banners",
      },

      {
        link: "communications/in-game-banners",
        component: InGameBannersList,
        name: "In-game Screens Banners",
      },

      {
        link: "communications/ads-banners",
        component: AdsBannersList,
        name: "Ads Banners",
      },
      {
        link: "communications/pop-ups",
        component: PopUpsList,
        name: "Pop-ups",
      },
      {
        link: "communications/promo-pop-ups",
        component: PromoPopUpsLists,
        name: "Promotion Pop-ups",
      },
      {
        link: "communications/optimove-popups",
        component: OptimovePopups,
        name: "Popup Templates",
      },
      {
        link: "communications/notifications",
        component: NotificationsList,
        name: "Notifications",
      },

      {
        link: "communications/email-notifications",
        component: () => <EmailNotificationsList />,
        name: "Emailing (Sendgrid)",
      },
      {
        link: "communications/email-notifications-cta",
        component: () => <EmailCtaList />,
        name: "Emailing CTA",
      },
    ],
  },

  {
    link: "games",

    name: "Games",
    icon: <RedditOutlined />,
    children: [
      {
        link: "games/games-list",
        component: GamesList,
        name: "Game Modes&Statuses",
      },
      {
        link: "games/game-accounts",
        name: "Mobile Gaming Accounts",
        component: GameAccountsList,
      },
      {
        link: "games/boosters",
        component: BoostersList,
        name: "Game Boosters",
      },
      {
        link: "games/challenges",
        component: ChallengesList,
        name: "Challenges",
      },
      {
        link: "games/blocked-game-modes",
        component: EditBlockedGameModes,
        name: "Blocked Game Modes",
      },
      {
        link: "games/blocked-game-modesV2",
        component: EditBlockedGameModesV2,
        name: "v2 Blocked Game Modes",
      },
    ],
  },
  {
    link: "raffles",
    component: RafflesList,
    name: "Raffles",
    icon: <FileImageOutlined />,
  },
  {
    link: "marketing",
    name: "Marketing",
    icon: <NotificationOutlined />,
    children: [
      {
        link: "marketing/promocodes",
        component: PromocodesSettings,
        name: "Promo Codes",
      },
      {
        link: "marketing/utm",
        component: UtmSettings,
        name: "UTM Bonuses",
      },
      {
        link: "marketing/promocodes/rewards",
        component: RewardsList,
        name: "Rewards Service",
      },
      {
        link: "marketing/giftoin",
        component: GiftoinSettings,
        name: "Giftoin",
      },
    ],
  },

  {
    link: "contests",
    name: "Contests",
    icon: <CheckCircleOutlined />,
    component: ContestsList,
  },

  {
    link: "marketplace",
    name: "Marketplace",
    icon: <TagsOutlined />,
    children: [
      {
        link: "marketplace/marketplace-items",
        component: MarketplaceSettings,
        name: "Marketplace Items",
      },
      {
        link: "marketplace/purchases",
        component: PurchasedGiftCardsList,
        name: "Recent Purchases",
      },
      {
        link: "/marketplace/codes/inventory",
        component: CodesList,
        name: "Internal Codes Storage",
      },
    ],
  },

  {
    link: "settings",
    name: "Settings",
    icon: <SettingOutlined />,

    children: [
      {
        link: "settings/json-manager",
        name: "IFRAMEs Settings",
        component: () => <ManagingJsonList />,
      },
      {
        link: "settings/subscriptions",
        component: SubscriptionList,
        name: "Premiums Copies",
      },
      {
        link: "settings/spin-collect-manager",
        name: "Spin&Collect Settings",
        component: () => <ManagingSpinCollectList />,
      },
      {
        link: "settings/tabs-navbar",
        component: EditNavbarAvatarTabs,
        name: "Navbar Tabs Avatar",
      },
      {
        link: "settings/positions-ingame-screen",
        component: EditPostionOfScreens,
        name: "Postions in-game screens",
      },

      {
        link: "settings/dynamic-ui",
        component: EditDynamicUI,
        name: "Dynamic UI",
      },
      {
        link: "settings/blacklist",
        component: BlacklistList,
        name: "BlackList",
      },
      {
        link: "settings/mobileConfig",
        component: EditMobileConfig,
        name: "Mobile Config",
      },
      {
        link: "settings/various",
        component: VariousConfig,
        name: "Various Config",
      },
    ],
  },

  {
    link: "games/:id",
    component: GameDetails,
  },

  {
    link: "special-events",
    name: "Special Events",
    icon: <CalendarOutlined />,
    component: SpecialEventsPage,
  },
  {
    link: "special-events/:id",
    component: EditSpecialEventPage,
  },
  {
    link: "products",
    name: "Buff Products",
    icon: <GiftOutlined />,

    children: [
      {
        link: "products/products",
        name: "Products",
        component: ProductsList,
      },
    ],
  },
  {
    link: "rewards-bulk",
    component: BulkRewards,
    name: "Bulk Rewards",
    icon: <CheckCircleOutlined />,
  },
  {
    link: "internal-offerwall",
    name: "Internal Offerwall",
    icon: <CheckSquareOutlined />,
    children: [
      {
        link: "internal-offerwall/advertisers",
        component: InternalOWAdvertisersPage,
        name: "Advertisers",
      },
      {
        link: "internal-offerwall/external",
        component: InternalOWExternalProvidersPage,
        name: "External",
      },
      {
        link: "internal-offerwall/settings",
        component: SettingsPage,
        name: "Settings",
      },
      {
        link: "internal-offerwall/advertisers/:advertiser",
        component: InternalOWAdvertiserPage,
      },
    ],
  },
  {
    link: "buff-play-v2",
    name: "Buff Play V2",
    icon: <BoldOutlined />,
    children: [
      {
        link: "buff-play-v2/utm",
        component: UtmSettingsV2,
        name: "UTM Bonuses",
      },
    ],
  },
]
