import { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Modal, Table } from "antd"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { UtmBonus } from "domains/utm-v2/store"
import { useStore } from "stores/utils/use-store"
import { Column } from "abstract/types/column.type"
import { UtmV2BonusEdit } from "../BonusesEdit"
import { utmV2BonusColumns } from "./columns"
import { DeleteIconButton, showDeleteConfirm, CreateNewElement } from "components"

export const UtmV2BonusesList: FC = observer(() => {
  const { dataStore: { utmV2BonusesStore, subscriptionStore } } = useStore()

  const paginationOptions: TablePaginationConfig = {
    onChange: async page => {
      utmV2BonusesStore.pagination.page = page
    },
    total: utmV2BonusesStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
  }

  const [isVisibleEditCreate, setEditCreateVisibility] = useState<"create" | "edit" | null>(null)

  useEffect(() => {
    utmV2BonusesStore.getData()
    subscriptionStore.getData()
  }, [utmV2BonusesStore, subscriptionStore])

  const onClose = () => {
    utmV2BonusesStore.clearSelectedElement()
    setEditCreateVisibility(null)
  }

  const data = () => {
    if (!utmV2BonusesStore || !utmV2BonusesStore.utmBonuses) {
      return []
    }

    return utmV2BonusesStore.utmBonuses.map(el => el)
  }

  const columns: Column[] = [
    ...utmV2BonusColumns,

    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (_: any, row: any) => (
        <DeleteIconButton
          onClick={e => {
            e.stopPropagation()
            showDeleteConfirm(`market item: ${row.name}`, undefined, async() => {
              utmV2BonusesStore.funcAfterSuccess = async() => await utmV2BonusesStore.getData()
              utmV2BonusesStore.delete(row.id)
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <CreateNewElement onClick={() => setEditCreateVisibility("create")} valueText="utm bonus" />
      <Table
        dataSource={data()}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={utmV2BonusesStore.isLoading}
        onRow={(record: UtmBonus) => ({
          onClick: () => {
            utmV2BonusesStore.selectedElement = record
            setEditCreateVisibility("edit")
          },
        })}
      />

      {isVisibleEditCreate && (
        <Modal
          visible
          title={isVisibleEditCreate === "edit" ? "Edit utm bonus" : "Create utm bonus"}
          width="700px"
          footer={null}
          onCancel={onClose}
          style={{ top: "10px" }}
        >
          <UtmV2BonusEdit editType={isVisibleEditCreate} onClose={onClose} />
        </Modal>
      )}
    </>
  )
})
