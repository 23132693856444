import React, { FC, useEffect, useState } from "react"

import moment from "moment"
import { observer } from "mobx-react"
import { Form, Input, InputNumber, Switch } from "antd"

import { useStore } from "stores/utils/use-store"
import { UtmBonus } from "domains/utm-v2/store"
import { SubmitCancelButtons } from "components"

interface FormValues extends Omit<UtmBonus, "expiresAt"> {
  expiresAt: moment.Moment;
}

export const UtmV2BonusEdit: FC<{ editType: "create" | "edit"; onClose: () => void }> = observer(({ editType, onClose }) => {
  const {
    dataStore: {
      utmV2BonusesStore: {
        selectedElement: {
          source,
          medium,
          campaign,
          content,
          extra,
          term,
          reward,
          active,
        },
      },
      utmV2BonusesStore,
      subscriptionStore,
    },
  } = useStore()

  useEffect(() => {
    subscriptionStore.getData()
  }, [subscriptionStore])

  const [form] = Form.useForm()

  const onFinishHandler = async(e: FormValues) => {
    const {
      source,
      medium,
      campaign,
      content,
      extra,
      term,
      reward,
      id,
      active,
    } = e

    const sendingData: UtmBonus = {
      id,
      source,
      medium,
      campaign,
      content,
      extra,
      term,
      reward: +reward,
      active,
    }

    utmV2BonusesStore.funcAfterSuccess = async() => {
      await utmV2BonusesStore.getData()
      onClose()
    }
    editType === "edit"
      ? await utmV2BonusesStore.update(sendingData)
      : await utmV2BonusesStore.create(sendingData)
  }

  const onFinishFailedHandler = (e: any) => {
    console.log(e)
  }

  const [isActive, setIsActive] = useState(active)

  return (
    <Form form={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler}>
      <Form.Item
        label="Source"
        name="source"
        initialValue={source}
        rules={[
          {
            required: true,
            message: "Please input source!",
          },
        ]}
      >
        <Input maxLength={25} />
      </Form.Item>
      <Form.Item
        label="Medium"
        name="medium"
        initialValue={medium}
      >
        <Input maxLength={25} />
      </Form.Item>
      <Form.Item
        label="Campaign"
        name="campaign"
        initialValue={campaign}
      >
        <Input maxLength={25} />
      </Form.Item>
      <Form.Item
        label="Content"
        name="content"
        initialValue={content}
      >
        <Input maxLength={250} />
      </Form.Item>
      <Form.Item
        label="Extra"
        name="extra"
        initialValue={extra}
      >
        <Input maxLength={250} />
      </Form.Item>
      <Form.Item
        label="Term"
        name="term"
        initialValue={term}
      >
        <Input maxLength={45} />
      </Form.Item>
      <Form.Item
        label="Reward"
        name="reward"
        initialValue={reward}
        rules={[
          {
            required: true,
            message: "Please input reward!",
          },
        ]}
      >
        <InputNumber max={10000} />
      </Form.Item>
      <Form.Item
        label="Active"
        name="active"
        initialValue={active}
      >
        <Switch
          defaultChecked={isActive}
          onChange={setIsActive}
        />
      </Form.Item>

      <SubmitCancelButtons onClose={onClose} isLoading={utmV2BonusesStore.isLoading} />
    </Form>
  )
})
