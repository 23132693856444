import { PaginationStore } from "stores/data/pagination.store"
import { action, computed, observable, transaction, makeObservable } from "mobx"
import { createBanner, getBanners, updateBanner, deleteBanner } from "../api"
import { EntityToUpdate } from "abstract/entity-to-update"
import { RootStore } from "stores/root.store"
import { Pixel } from "domains/managingJson/store/types"

export type BannerPlatform = "mobile" | "pc" | "ios" | "android";

export interface BannerFilters {
  platform: BannerPlatform;
}
type UserTypes = "REGULAR" | "PREMIUM" | "PREMIUM LITE" | "PREMIUM PLUS" | "PREMIUM ELITE";
export class Banner {
  constructor(
    public id: string,
    public name: string,
    public img: string,
    public link: string | null,
    public priority: number | null,
    public isActive: boolean | null,
    public createdAt: Date,
    public updatedAt: Date,
    public platform: BannerPlatform,
    public pixels: Pixel[],
    public countries?: string[] | null,
    public isTest?: boolean | null,
    public activeUntil?: Date | string | null,
    public startDate?: Date | null,
    public activeDay?: number,
    public excludeCountries?: string[] | null,
    public userTypes?: UserTypes[] | null,
    public daysSinceRegistration?: number | null,
    public type?: "IMAGE" | "VIDEO" | null,
    public dailyImpressionCapLimit?: number | null,
    public weeklyImpressionCapLimit?: number | null,
    public extendedDurationSeconds?: number | null,
  ) {}
}

export class BannersStore extends PaginationStore<Banner, BannerFilters> {
  @observable
  private _banners: Banner[]
  @observable
  private _selectedElement: Banner = {} as Banner

  @observable
  private isLoaderForm: boolean = false

  @observable
  private isImageError: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
  }

  async getData(): Promise<Banner[]> {
    this.resetFuncAfterSuccess()
    const { page, limit } = this.pagination

    const res = await this.defaultFunction(getBanners, this.filter, page, limit)
    if (res) {
      transaction(() => {
        this._banners = res.data
        this.total = res.total
      })
    }

    return res.data
  }

  async update(newBanner: EntityToUpdate<Banner>) {
    await this.defaultFunction(updateBanner, this._selectedElement.id, newBanner)
  }

  async create(banner: EntityToUpdate<Banner>) {
    await this.defaultFunction(createBanner, banner)
  }

  async delete(id: string) {
    await this.defaultFunction(deleteBanner, id)
  }

  @computed
  get banners(): Banner[] {
    return this._banners
  }

  @computed
  get selectedElement(): Banner {
    return this._selectedElement
  }

  @computed
  get getLoadingForm(): boolean {
    return this.isLoaderForm
  }

  @action.bound
  setLoading(value: boolean) {
    this.isLoaderForm = value
  }

  @computed
  get isValidationError(): boolean {
    return this.isImageError
  }

  @action.bound
  public setImageValidationError() {
    this.isImageError = true
    setTimeout(() => (this.isImageError = false), 2500)
  }

  set selectedElement(value: Banner) {
    this._selectedElement = value
  }

  @action.bound
  public clearSelectedElement() {
    this._selectedElement = {} as Banner
  }
}
