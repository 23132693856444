export const utmV2BonusColumns = [
  { title: "Source", dataIndex: "source", key: "source" },
  { title: "Medium", dataIndex: "medium", key: "medium" },
  { title: "Campaign", dataIndex: "campaign", key: "campaign" },
  { title: "Content", dataIndex: "content", key: "content" },
  { title: "Extra", dataIndex: "extra", key: "extra" },
  { title: "Term", dataIndex: "term", key: "term" },
  { title: "Reward", dataIndex: "reward", key: "reward" },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: isActive => (isActive ? "true" : "false"),
  },
]
