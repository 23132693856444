import { RootStore } from "../root.store"
import { AuthStore } from "./auth.store"

import { MarketplaceRegionsStore } from "domains/marketplace/store/regions.store"
import { GameAccountStore } from "domains/game-accounts/store/game-account.store"
import { GameAccountDetailsStore } from "domains/game-accounts/store/game-account-details.store"
import { BannersStore } from "domains/banners/store/banners.store"
import { BoostersStore } from "domains/boosters/store/boosters.store"
import { MarketplaceStore } from "domains/marketplace/store/marketplace.store"
import { ItemProviderStore } from "domains/marketplace/store/item-provider.store"
import { InternalMarketplaceStore } from "domains/marketplace/store/internal-marketplace.store"
import { MarketplaceCategoriesStore } from "domains/marketplace/store/categories.store"
import { SubscriptionStore } from "domains/subscription/store/subscription.store"
import { ManagingSpinCollectStore } from "domains/managingSpinCollect/store/managingSpinCollect.store"
import { PromoPopUpsStore } from "domains/promo-pop-ups/store/promo-pop-ups.store"
import { GamesStore } from "domains/games/store/games.store"
import { ManagingJsonStore } from "domains/managingJson/store/managingJson.store"
import { ContestsStore } from "domains/contests/store/contests.store"
import { AccountStore } from "domains/accounts/store/account.store"
import { AccountDetailsStore } from "domains/accounts/store/account-details.store"
import { GiftCardsStore } from "domains/marketplace/store/gift-cards.store"
import { PurchasedGiftCardsStore } from "domains/purchased-gift-cards/store/purchased-gift-cards.store"
import { RafflesStore } from "domains/raffles/store"
import { ChallengesStore } from "domains/challenges/store/challenges.store"
import { ProductsStore } from "domains/products/store/products.store"
import { GiftoinStore } from "domains/giftoin/store/giftoin.store"
//
import { EmailNotificationsStore } from "../../domains/email-notifications/store/email-notifications.store"
import { FilesStore } from "./files.store"
import { PopUpsStore } from "../../domains/pop-ups/store/pop-ups.store"
import { TierStore } from "domains/tiers/store/tiers.store"
//
import { NotificationsStore } from "domains/notifications/store/notifications.store"
import { UtmTiersStore, UtmBonusesStore } from "domains/utm/store"
import { UtmV2BonusesStore } from "domains/utm-v2/store"
import { ParentPromocodesStore } from "domains/promocodes/store/parent-promocodes.store"
import { ChildPromocodesStore } from "domains/promocodes/store/child-promocodes.store"
import { PromocodesGroupsStore } from "domains/promocodes/store/groups.store"
import { PromocodesRewardsStore } from "domains/promocodes/store/rewards.store"
import { SubCodesStore } from "domains/codesInventory/store/sub-codes-store"
import { CodesStore } from "domains/codesInventory/store/codes-store"
import { BlacklistStore } from "domains/blacklist/store/blacklist.store"
import { AccountTransactionsStore } from "domains/accounts/store/account-transactions.store"
import { EmailCTAsStore } from "domains/emailsCTA/store/emails-cta.store"

export class DataStore {
  public accountStore: AccountStore
  public gameAccountStore: GameAccountStore
  public accountDetailsStore: AccountDetailsStore
  public accountTransactionsStore: AccountTransactionsStore
  public blacklistStore: BlacklistStore
  public gameAccountDetailsStore: GameAccountDetailsStore
  public authStore: AuthStore
  public marketplaceStore: MarketplaceStore
  public marketplaceRegionsStore: MarketplaceRegionsStore
  public internalMarketplaceStore: InternalMarketplaceStore
  public managingJsonStore: ManagingJsonStore
  public bannersStore: BannersStore
  public filesStore: FilesStore
  public popUpsStore: PopUpsStore
  public emailsCtaStore: EmailCTAsStore
  public contestsStore: ContestsStore
  public boostersStore: BoostersStore
  public tierStore: TierStore
  public itemProviderStore: ItemProviderStore
  public marketplaceCategoriesStore: MarketplaceCategoriesStore
  public giftCardsStore: GiftCardsStore
  public subscriptionStore: SubscriptionStore
  public purchasedGiftCardsStore: PurchasedGiftCardsStore
  public utmBonusesStore: UtmBonusesStore
  public utmV2BonusesStore: UtmV2BonusesStore
  public utmTiersStore: UtmTiersStore
  public rafflesStore: RafflesStore
  public subCodesStore: SubCodesStore
  public codesStore: CodesStore
  public challengesStore: ChallengesStore
  public productsStore: ProductsStore
  public promoPopupsStore: PromoPopUpsStore
  public emailNotificationsStore: EmailNotificationsStore
  public notificationsStore: NotificationsStore
  public managingSpinCollectStore: ManagingSpinCollectStore
  public gamesStore: GamesStore
  public parentPromocodesStore: ParentPromocodesStore
  public childPromocodesStore: ChildPromocodesStore
  public promocodesGroupsStore: PromocodesGroupsStore
  public promocodesRewardsStore: PromocodesRewardsStore
  public giftoinStore: GiftoinStore

  constructor(rootStore: RootStore) {
    this.accountStore = new AccountStore(rootStore)
    this.blacklistStore = new BlacklistStore(rootStore)
    this.gameAccountStore = new GameAccountStore(rootStore)
    this.accountDetailsStore = new AccountDetailsStore(rootStore)
    this.accountTransactionsStore = new AccountTransactionsStore(rootStore)
    this.gameAccountDetailsStore = new GameAccountDetailsStore(rootStore)
    this.authStore = new AuthStore()
    this.marketplaceStore = new MarketplaceStore(rootStore)
    this.marketplaceRegionsStore = new MarketplaceRegionsStore(rootStore)
    this.internalMarketplaceStore = new InternalMarketplaceStore(rootStore)
    this.bannersStore = new BannersStore(rootStore)
    this.filesStore = new FilesStore(rootStore)
    this.popUpsStore = new PopUpsStore(rootStore)
    this.emailsCtaStore = new EmailCTAsStore(rootStore)
    this.promoPopupsStore = new PromoPopUpsStore(rootStore)
    this.contestsStore = new ContestsStore(rootStore)
    this.boostersStore = new BoostersStore(rootStore)
    this.itemProviderStore = new ItemProviderStore(rootStore)
    this.marketplaceCategoriesStore = new MarketplaceCategoriesStore(rootStore)
    this.tierStore = new TierStore(rootStore)
    this.giftCardsStore = new GiftCardsStore(rootStore)
    this.subscriptionStore = new SubscriptionStore(rootStore)
    this.purchasedGiftCardsStore = new PurchasedGiftCardsStore(rootStore)
    this.utmBonusesStore = new UtmBonusesStore(rootStore)
    this.utmV2BonusesStore = new UtmV2BonusesStore(rootStore)
    this.utmTiersStore = new UtmTiersStore(rootStore)
    this.rafflesStore = new RafflesStore(rootStore)
    this.codesStore = new CodesStore(rootStore)
    this.subCodesStore = new SubCodesStore(rootStore)
    this.challengesStore = new ChallengesStore(rootStore)
    this.productsStore = new ProductsStore(rootStore)
    //
    this.emailNotificationsStore = new EmailNotificationsStore(rootStore)
    //
    this.notificationsStore = new NotificationsStore(rootStore)
    this.managingJsonStore = new ManagingJsonStore(rootStore)
    this.managingSpinCollectStore = new ManagingSpinCollectStore(rootStore)
    this.gamesStore = new GamesStore(rootStore)
    this.parentPromocodesStore = new ParentPromocodesStore(rootStore)
    this.childPromocodesStore = new ChildPromocodesStore(rootStore)
    this.promocodesGroupsStore = new PromocodesGroupsStore(rootStore)
    this.promocodesRewardsStore = new PromocodesRewardsStore(rootStore)
    this.giftoinStore = new GiftoinStore(rootStore)
  }
}
